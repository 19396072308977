import React, { useState, useEffect } from 'react';
import DOMPurify from "dompurify";
import { Layout, } from 'antd';
export default function Privacy ()  {
    const [htmlContent, setHtmlContent] = useState('');
    const { Content } = Layout

    useEffect(() => {
        // Fetch the HTML file
        fetch('privacy.html')
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.text();
          })
          .then((data) => {
            // Set the HTML content to the state
            setHtmlContent(data);
            setHtmlContent(DOMPurify.sanitize(data));
          })
          .catch((error) => {
            console.error('Error fetching HTML file:', error);
          });
      }, []);
    return (
        <div>
            <div className='container' style={{paddingTop: '150px', paddingBottom: '150px'}}>
                <p className='title'>Privacy Policy</p>
                {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
                <p><span>This privacy policy is a legally binding document between you and XPAY both terms defined below. The terms of this privacy policy will be effective upon your registration on the website as defined below and will govern the relationship between you and XPAY for your use of the website. This document is published and shall be construed in accordance with the provisions of the information technology reasonable security practices and procedures and sensitive personal data or information rules, 2011 under the information technology act, 2000; that require publishing of the privacy policy for collection, use, storage and transfer of personal information including sensitive personal information.</span></p>
                <p><span>Please read this privacy policy carefully. By registering an account on the website and agreeing to the privacy policy by checking the privacy policy tickbox, you indicate that you understand, agree and consent to this privacy policy. If you do not agree with the terms of this privacy policy, please do not accept the privacy policy and/or use this website. You hereby provide your unconditional consent or agreements to XPAY as provided under the information technology act, 2000 and the rules and regulations made under therein.</span></p>
                <p><span>By providing Us Your Information or by making use of the services provided by the Website, You hereby consent to the collection, storage, processing, disclosure and/or transfer of any or all of Your Personal Information as defined below, Sensitive Personal Information as defined below and Non-Personal Information as defined below by XPAY, as specified under this Privacy Policy. You further agree that such collection, use, storage, disclosure and/or transfer of Your Information shall not cause any loss or wrongful gain to You or any other person.</span></p>
                <p><span>Conus Investments Pvt Ltd and any other person, directly or indirectly, controlled by, controlling, or under common control with, XPAY eWallet app, &quot;XPAY&quot; is/are concerned about the privacy of the data and information of users including merchants and buyers/customers whether registered or non-registered accessing, offering, selling or purchasing products or services on the websites, mobile sites or mobile applications of a XPAY &quot;Website&quot; and otherwise doing business with a XPAY.</span></p>
                <p><span>The terms&nbsp;&ldquo;We&rdquo; / &ldquo;Us&rdquo; / &ldquo;Our&rdquo;&nbsp;individually and collectively refer to each entity being part of the definition of XPAY and the terms&nbsp;&ldquo;You&rdquo; / &ldquo;Your&rdquo; / &ldquo;Yourself&rdquo;&nbsp;refer to the users.</span></p>
                <p><span>This Privacy Policy is a contract between You and the respective XPAY whose Website You use or access or You otherwise deal with. This Privacy Policy shall be read together with the respective terms of use or other terms and conditions of the respective XPAY and its respective Website or nature of business of the Website.</span></p>
                <ol className='privacy-list'>
                    <li>
                        <span>XPAY has provided this Privacy Policy to familiarize You with:</span>
                        <ol>
                            <li>The type of data or information that You share with or provide to any XPAY, and that any XPAY collects from You;</li>
                            <li>The purpose for collection and usage of such data or information from You;</li>
                            <li>The information security practices and policies of XPAY; and</li>
                            <li>The policy of XPAY on disclosing, sharing or transferring Your data or information with/to third parties.</li>
                        </ol>
                    </li>
                    <li>Amendment of Privacy Policy</li>
                    <li>Information collected and storage of Information</li>
                    <li>Personal Information
                        <ol>
                            <li>Personal Information is information or data collected that can be used to identify You. Personal Information for the purposes of this Privacy Policy shall include, but not be limited to:</li>
                            <ol type="i">
                                <li>Your name, parent’s name, address, photo, telephone number, e-mail address or other contact information;</li>
                                <li>Your date of birth;</li>
                                <li>Your gender, marital status;</li>
                                <li>Your occupation;</li>
                                <li>Your internet protocol address;</li>
                                <li>Identification code of the communication device which You use to access the Website or otherwise deal with any XPAY;</li>
                                <li>Any other information provided by You during Your registration process on the Website other than Sensitive Personal Information;</li>
                                <li>Information provided by You towards complying with know your customer norms, either as part of Our internal policies and as may be required under applicable laws;</li>
                                <li>Information relating to query all packages installed on your device. The information collected through this process, including information about the other apps installed on your device, will be used to detect fraudulent activities and determine your creditworthiness. This information will be used to educate an algorithm that calculates your credit score and enables us to offer you digital banking, cashbacks, and financial products such as loans.</li>
                                <li>Details that you have given permission to upload, sync or import such as phone status & identity, contacts, calendar, camera, microphone or short messaging system any information set out in this Clause pertaining to third parties that is provided to Us, during Your use of the Website; or</li>
                            </ol>
                            <li>
                                Such Personal Information may be collected in various ways including during the course of You:
                                <ol type="i">
                                    <li>Registering as a user on the Website;</li>
                                    <li>Registering as a merchant on the Website;</li>
                                    <li>Availing certain services offered on the Website. Such instances include but are not limited to making or participating in any online survey or contest, communicating with the customer service of any XPAY by phone, email or otherwise or posting user reviews on the services / items available on the Website;</li>
                                    <li>Otherwise doing business, transacting or attempting to transact on the Website; or</li>
                                    <li>Otherwise dealing with any of XPAY.</li>
                                </ol>
                            </li>
                            <li>
                                We may receive Personal Information about You from third parties, such as social media services, commercially available sources and partners. If You access the Website through a social media service or connect a service on the Website to a social media service, the Information We collect may include Your user name associated with that social media service, any information or content the social media service has the right to share with us, such as Your profile picture, email address or friends list, and any information You have made public in connection with that social media service. When You access the Website or otherwise deal with any XPAY through social media services or when You connect any Website to social media services, You are authorizing XPAY to collect, store, use and retain such information and content in accordance with this Privacy Policy.</li>
                        </ol>
                    </li>
                <li>Sensitive Personal Information
                    <ol>
                        <li>Sensitive Personal Information is information collected that consists of information relating to:
                            <ol type="i">
                                <li>passwords,</li>
                                <li>financial information such as bank account or Credit Card or Debit Card, or other linked payment instrument details,</li>
                                <li>physical, physiological and mental health condition,</li>
                                <li>sexual orientation,</li>
                                <li>medical records and history,</li>
                                <li>biometric information,</li>
                                <li>any detail relating to the above points as provided by You,</li>
                                <li>any of the information received under the above points by XPAY Entities for processing, stored or processed under lawful contract otherwise</li>
                            </ol>
                        </li>
                        <li>Such Sensitive Personal Information may be collected in various ways including during the course of You:
                            <ol type="i">
                                <li>registering as a user on the Website;</li>
                                <li>registering as a merchant on the Website;</li>
                                <li>availing certain services offered on the Website;</li>
                                <li>otherwise doing business, transacting or attempting to transact on the Website; or</li>
                                <li>otherwise dealing with any of XPAY.</li>
                            </ol>
                        </li>
                    </ol>
                        <p>Provided any such Sensitive Personal Information that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law for the time being in force shall not be regarded as Sensitive Personal Information and be regarded as Personal Information.</p>
                </li>
                <li>Non-Personal Information
                        <ol>
                            <li>XPAY may also collect information other than Personal Information or Sensitive Personal Information from You through the Website when You visit and / or use the Website. Such information may be stored in server logs. This Non-Personal Information would not assist XPAY to identify You personally.</li>
                            <li>This Non-Personal Information may include:
                                <ol type="i">
                                    <li>Your geographic location;</li>
                                    <li>details of Your telecom service provider or internet service provider;</li>
                                    <li>the type of browser (Internet Explorer, Firefox, Opera, Google Chrome etc.);</li>
                                    <li>the operating system of Your system, device and the Website You last visited before visiting the Website; or</li>
                                    <li>clickstream data relating to Your activity on the Website, including but not limited to the duration of Your stay on the Website, date and time of Your access of the Website.</li>
                                </ol>
                            </li>
                            <li>Non-Personal Information is collected through various ways such through as the use of cookies. XPAY may store temporary or permanent ‘cookies’ on Your computer. You can erase or choose to block these cookies from Your computer. You can configure Your computer’s browser to alert You when We attempt to send You a cookie with an option to accept or refuse the cookie. If You have turned cookies off, You may be prevented from using certain features of the Website;</li>
                            <li>Advertisements: XPAY may use Third Party Service Providers to serve advertisements on behalf of XPAY across the internet and sometimes on the Website. They may collect Non-Personal Information about Your visits to the Website, and Your interaction with Our products and services on the Website.</li>
                        </ol>
                    </li>
                    <li>Representations and Warranties
                        <ul>
                            <li>You hereby represent to XPAY that, at all times during Your registration with Us:
                                <ol>
                                    <li>the information You provide to XPAY is and shall be authentic, correct, current and updated, and that You have all the rights, permissions and consents as may be required to provide such information (including Information pertaining to any third parties) to the XPAY;</li>
                                    <li>Your providing the information to XPAY and the consequent storage, collection, usage, transfer, access or processing of the same by XPAY shall not be in violation of any third party agreement, laws, charter documents, judgments, orders and decrees.</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <p>The officers, directors, contractors or agents of XPAY shall not be responsible for the authenticity of the information that You or any other user provide to any of XPAY.</p>
                <li>Purpose of collecting, using, storing and processing Your Personal Information/Non-Personal Information/Sensitive Personal Information
                        <ul>
                            <li>XPAY reserves the right to collect, use, store, process and disclose Your Personal Information/Non-Personal Information/Sensitive Personal Information for any purpose that may be permissible based on the consent obtained from You. XPAY uses Personal Information/Non-Personal Information/Sensitive Personal Information that is collected for lawful purposes associated with the functions and activities of XPAY, while also respecting your privacy. Such purposes (“Purposes”) include but are not limited to:
                                <ol type="i">
                                    <li>facilitating Your use of the Website or other services of XPAY;</li>
                                    <li>responding to Your inquiries or fulfilling Your requests for information about the various products and services offered on the Website;</li>
                                    <li>providing You with information about products and services available on the Website and sending You information, materials, and offers about products and services of XPAY;</li>
                                    <li>sending You important information regarding the Website, changes in terms and conditions, user agreements, and policies and/or other administrative information;</li>
                                    <li>sending You surveys and marketing communications that XPAY believes may be of interest to You;</li>
                                    <li>personalizing Your experience on the Website by presenting advertisements, products and offers tailored to Your preferences;</li>
                                    <li>helping You address Your problems incurred on the Website including addressing any technical problems;</li>
                                    <li>if You purchase any product or avail any service from the Website, completing and fulfilling Your purchase. For example, having Your payments processed, communicating with You regarding Your purchase, and providing You with related customer service;</li>
                                    <li>properly administering the Website;</li>
                                    <li>conducting internal reviews and data analysis for the Website (e.g., to determine the number of visitors to specific pages within the Website);</li>
                                    <li>improving the services, content and advertising on the Website;</li>
                                    <li>facilitating various programmes and initiatives launched by XPAY by itself or through a collaboration with Third Party Service Providers or Banks;</li>
                                    <li>analyzing how Our services are used, to measure the effectiveness of advertisements, to facilitate payments;</li>
                                    <li>conducting academic research and surveys;</li>
                                    <li>protecting the integrity of the Website;</li>
                                    <li>responding to legal, judicial, quasi-judicial process and providing information on law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law;</li>
                                    <li>conducting analytical studies on various aspects including user behavior, user preferences, etc.;</li>
                                    <li>permitting third parties who may need to contact users who have bought products from the Website to facilitate installation, service and any other product-related support;</li>
                                    <li>implementing information security practices;</li>
                                    <li>determining any security breaches, computer contaminant or computer virus;</li>
                                    <li>investigating, preventing, or taking action regarding illegal activities and suspected fraud;</li>
                                    <li>undertaking forensics of the concerned computer resource as a part of investigation or internal audit;</li>
                                    <li>tracing computer resources or any person who may have contravened, or is suspected of having or being likely to contravene, any provision of law including the Information Technology Act, 2000 that is likely to have an adverse impact on the services provided on any Website or by XPAY;</li>
                                    <li>enabling a potential buyer or investor to evaluate the business of XPAY; or</li>
                                    <li>sharing, disclosing or transferring such data in the event of any merger, acquisition, reorganization or sale of assets or business of XPAY.</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li> You hereby agree and acknowledge that the Personal Information/Non-Personal Information/Sensitive Personal Information so collected is for a lawful purpose, connected with a function or activity of XPAY or any person on their respective behalf, and the collection of Personal Information/Non-Personal Information/Sensitive Personal Information is necessary for the Purposes.</li>
                    <li>Storage and Processing of Your Personal Information/Non-Personal Information/Sensitive Personal Information
                        <ol>
                            <li>Your Personal Information/Non-Personal Information/Sensitive Personal Information will primarily be stored in electronic form. However, certain data may also be stored in physical form.</li>
                            <li>We may store, collect, process and use Your data in countries other than the Republic of Maldives to the extent permissible under applicable laws.</li>
                            <li>We may enter into agreements with third parties (in or outside of Maldives) to store or process Your Personal Information/Non-Personal Information/Sensitive Personal Information. These third parties may have their own security standards to safeguard Your Personal Information/Non-Personal Information/Sensitive Personal Information and We will, on a commercially reasonable basis, require such third parties to adopt reasonable security standards to safeguard Your Personal Information/Non-Personal Information/Sensitive Personal Information, as per the requirements of applicable laws.</li>
                            <li>Sharing and disclosure of Your Personal Information/Non-Personal Information/Sensitive Personal Information: You hereby unconditionally agree and permit that XPAY may transfer, share, disclose or part with all or any of Your Personal Information/Non-Personal Information/Sensitive Personal Information, within and outside of the Republic of Maldives to XPAY, Third Party Service Providers / Partners / Banks and Financial Institutions for one or more of the Purposes, government agencies upon directions of such agency, or to any other third party as may be required under applicable law. In case of any Personal Information/Sensitive Personal Information so transferred, shared, disclosed or parted with, We will contractually oblige the receiving parties of the information to ensure the same level of data protection adopted by XPAY is adhered to by the receiving party.</li>
                            <li>You acknowledge and agree that, to the extent permissible under applicable laws, it is adequate that when XPAY transfer Your information to any other entity within or outside Your country of residence, XPAY will place contractual obligations on the transferee which will oblige the transferee to adhere to the provisions of this Privacy Policy.</li>
                            <li>XPAY may transfer/disclose/share Non-Personal Information to third parties who support Our business, such as providing technical infrastructure services, analyzing how Our services are used, measuring the effectiveness of advertisements, providing customer/buyer services, facilitating payments, or conducting academic research and surveys. Such transferees shall adhere to confidentiality obligations consistent with this Privacy Policy.</li>
                            <li>Notwithstanding the above, We use other third parties such as credit/debit card processing companies, payment gateways, pre-paid cards, etc., to enable You to make payments for buying products or availing services on the Website. When You sign up for these services, You may have the ability to save Your card details for future reference and faster future payments. In such case, We may share or disclose Your relevant Personal Information as necessary for the third parties to provide such services, including Your name, residence, and email address. The processing of payments or authorization is solely in accordance with these third parties’ policies, terms, and conditions, and We are not in any manner responsible or liable to You or any third party for any delay or failure at their end in processing the payments.</li>
                            <li>XPAY may also share Personal Information/Sensitive Personal Information with such third parties to whom XPAY believes it is necessary to do so in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of various terms and conditions or Our policies.</li>
                            <li>We reserve the right to disclose Your Personal Information/Non-Personal Information/Sensitive Personal Information when required to do so by law or regulation, or under any legal obligation or order under law or in response to a request from a law enforcement or governmental agency or judicial, quasi-judicial, or any other statutory or constitutional authority or to establish or exercise Our legal rights or defend against legal claims.</li>
                            <li>You further agree that such disclosure, sharing and transfer of Your Personal Information/Non-Personal Information/Sensitive Personal Information shall not cause any wrongful loss to You or to any third party, or any wrongful gain to Us or to any third party.</li>
                        </ol>
                    </li>
                    <li>Links to third party websites
                        <ol>
                            <li>Links to third-party advertisements, third-party websites, or any third-party electronic communication service may be provided on the Website which are operated by third parties and are not controlled by, or affiliated with, or associated with, XPAY unless expressly specified on the Website.</li>
                            <li>XPAY is not responsible for any form of transmission, whatsoever, received by You from any third-party website. Accordingly, XPAY does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such third-party websites, nor does XPAY control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, messages, or other materials available on such third-party websites. The inclusion or exclusion does not imply any endorsement by XPAY of the third-party websites, the website's provider, or the information on the third-party website. The information provided by You to such third-party websites shall be governed in accordance with the privacy policies of such third-party websites, and it is recommended that You review the privacy policy of such third-party websites prior to using such websites.</li>
                        </ol>
                    </li>
                    <li>Security & Retention
                        <ol>
                            <li>The security of Your Personal Information/Sensitive Personal Information is important to us. XPAY strives to ensure the security of Your Personal Information/Sensitive Personal Information and to protect Your Personal Information/Sensitive Personal Information against unauthorized access or unauthorized alteration, disclosure, or destruction. For this purpose, XPAY adopts internal reviews of the data collection, storage, and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where XPAY stores Your Personal Information/Sensitive Personal Information. XPAY shall adopt reasonable security practices and procedures as mandated under applicable laws for the protection of Your Personal Information/Sensitive Personal Information. Provided that, Your right to claim damages shall be limited to the right to claim only statutory damages under the Information Technology Act, 2000, and You hereby waive and release all XPAY from any claim of damages under contract or under tort, to the extent permissible under applicable laws.</li>
                            <li>If You choose a payment gateway to complete any transaction on the Website, then Your credit card/debit card data may be stored in compliance with industry standards/recommended data security standards for the security of financial information such as the Payment Card Industry Data Security Standard.</li>
                            <li>XPAY may share Your Personal Information/Non-Personal Information with third parties under a confidentiality agreement which inter alia provides that such third parties shall not disclose the Personal Information/Non-Personal Information further unless such disclosure is for the Purpose. However, XPAY is not responsible for any breach of security or for any actions of any third parties that receive Your Personal Information/Non-Personal Information. XPAY is not liable for any loss or injury caused to You as a result of You providing Your Personal Information/Non-Personal Information to any third party (including any third-party websites, even if links to such third party websites are provided on the Website).</li>
                            <li>Notwithstanding anything contained in this Policy or elsewhere, to the extent permissible under applicable laws, XPAY shall not be held responsible for any loss, damage, or misuse of Your Personal Information/Non-Personal Information/Sensitive Personal Information, if such loss, damage, or misuse is attributable to a Force Majeure Event (as defined below).</li>
                            <li>A Force Majeure Event shall mean any event that is beyond the reasonable control of XPAY and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to computer, computer system or computer network, computer crashes, breach of security and encryption (provided beyond reasonable control of XPAY), power or electricity failure or unavailability of adequate power or electricity.</li>
                            <li>While We will endeavor to take all reasonable and appropriate steps to keep secure any Personal Information/Non-Personal Information/Sensitive Personal Information which We hold about You and prevent unauthorized access, You acknowledge that the internet or computer networks are not fully secure and that We cannot provide any absolute assurance regarding the security of Your Personal Information/Non-Personal Information/Sensitive Personal Information.</li>
                            <li>You agree that all Sensitive Personal Information shall be retained till such time required for the Purpose or required under applicable law, whichever is later. Personal Information/Non-Personal Information will be retained indefinitely.</li>
                        </ol>
                    </li>
                    <li>Deletion of XPAY account (Only for IOS users)
                        <ol>
                            <li>On receipt of an account deletion request, XPAY will delete your data and immediately stop collecting, storing, and using your personal information and other data, except as otherwise required by applicable laws and regulations or industry standards. For example, XPAY will retain your transaction-related details and corresponding data for compliance purposes as per RBI, PMLA, and PPI Act & Guidelines respectively, for up to Ten (10) years.</li>
                    <li>Legal Consequences of Account Deletion
                        <ol>
                            <li>You will not be able to use/access this account to log in (signing in) to the XPAY website, app, with your previous login credentials.</li>
                            <li>You will not be able to perform any operations that require the account access.</li>
                            <li>You will not be able to retrieve the personal information, transaction records, business data, and historical information under the account.</li>
                            <li>You will not be able to use XPAY services.</li>
                            <li>If you wish to create an account with the same mobile number and/or email id, through which you had raised your account deletion request. In such case, XPAY shall consider and register you on its platform as a new customer.</li>
                            <li>Notwithstanding contrary to the above, the deletion of your account does not mean that all your account operations and responsibilities before your account deletion are exempted or mitigated.</li>
                        </ol>
                    </li>
                        </ol>
                    </li>
                    <li>Indemnity
                        <p>You shall indemnify and hold harmless XPAY, its officers, directors, contractors, or agents and any third parties relying on the Information provided by You for any losses, including all claims, damages, liabilities, deficiencies, assessments, interest, awards, settlements, penalties, fines, costs or expenses, suffered, incurred, sustained by, or imposed on XPAY, as a result of, arising out of, with respect to, in connection with, or by reason of a breach or non-performance of any of the terms, conditions, representations, warranties, or covenants contained in this Privacy Policy by You.</p>
                    </li>
                    <li>User discretion and opt-out
                        <p>You agree and acknowledge that You are providing Your Personal Information/Non-Personal Information/Sensitive Personal Information out of Your own free will. You have an option not to provide or permit XPAY to collect Your Personal Information/Non-Personal Information/Sensitive Personal Information or later on withdraw Your consent with respect to such Personal Information/Non-Personal Information/Sensitive Personal Information so provided herein, in writing, by sending an email to the grievance officer, details of whom are set out in clause 14 of this Privacy Policy, or to such other electronic address of the respective XPAY as may be notified to You. In such case, You should neither visit the Website nor use any services provided by XPAY nor shall contact XPAY. Further, XPAY may not deliver products to You, upon Your order, or XPAY may deny You access from using certain services offered on the Website.</p>
                        <ol type="a">
                            <li>You can review, add, or update Your Personal Information/Sensitive Personal Information on a regular basis. Any Personal Information/Sensitive Personal Information found to be inaccurate or deficient can be amended or corrected by You, as feasible. However, We will not be responsible for the authenticity of the Personal Information/Sensitive Personal Information provided by You to Us or to any other entity acting on behalf of Us. Kindly note that XPAY shall retain Your previous Personal Information/Sensitive Personal Information in its records, as required under applicable law.</li>
                        </ol>
                    </li>
                    <li>Grievance Officer
                        <p>If You find any discrepancies or have any grievances in relation to the collection, storage, use, disclosure and transfer of Your Personal Information/Sensitive Personal Information under this Privacy Policy or any terms of the terms of use of XPAY, and other terms and conditions or policies of any XPAY, please contact the following personnel:</p>
                        <p>E-mail: info@xpay.com</p>
                        <p>Working Days: Sunday to Thursday</p>
                        <p>Working Hours: 9:30 am to 6:30 pm</p>
                        <ol type="a">
                            <li>We will strive to address Your feedback and concerns in a timely and effective manner. The details of the grievance officer may be changed by Us from time to time by updating this Privacy Policy.</li>
                        </ol>
                    </li>
                    <li>Business / Assets Sale or Transfers
                        <p>In the event of a merger, acquisition, reorganization or sale of assets or business of XPAY to a third party or in the event of bankruptcy of XPAY, XPAY may transfer or otherwise share some or all of its assets, including Your Personal Information/Non-Personal Information/Sensitive Personal Information to such third party. Should such a sale or transfer occur, XPAY will reasonably ensure that the Personal Information/Non-Personal Information/Sensitive Personal Information You have provided and which We have collected is stored and used by the transferee in a manner that is consistent with this Privacy Policy and with applicable laws. Any third party to which XPAY transfer or sell as aforesaid will have the right to continue to use the Personal Information/Non-Personal Information/Sensitive Personal Information that You provide to Us or collected by Us immediately prior to such transfer or sale for the Purposes.</p>
                    </li>
                    <li>Further Acknowledgements
                        <p>You hereby acknowledge and agree that this Privacy Policy:</p>
                        <ol type="a">
                            <li>is clear and easily accessible and provide statements of policies and practices XPAY with respect to Personal Information/Non-Personal Information/Sensitive Personal Information;</li>
                            <li>provides for the various types of Personal Information/Non-Personal Information/Sensitive Personal Information to be collected;</li>
                            <li>provides for the purposes of collection and usage of the Personal Information/Non-Personal Information/Sensitive Personal Information;</li>
                            <li>provides for disclosure of Personal Information/Non-Personal Information/Sensitive Personal Information; and</li>
                            <li>provides for reasonable security practices and procedures as per the applicable laws, rules, and regulations for the time being in force.</li>
                        </ol>
                    </li>
                    <li>Definitions
                        <p>“Third Party Service Providers” or “Partners” shall mean any third party entity with which any of XPAY has a contractual arrangement for providing any kind of services to the respective XPAY; and</p>
                        <p>“Banks” shall mean various banks, financial institutions, payment system providers who are licensed by the Maldives Monetary Authority and with whom any of the XPAY has entered into an agreement.</p>
                    </li>
                </ol> 
            </div>
        </div>
    
)}