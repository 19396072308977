import React, {useState, useEffect} from 'react';
import { About } from './Components/About';
import { BrowserRouter as Router } from "react-router-dom";
import Features from './Components/features';
import Promotions from './Components/promotions';
import Carousel from './Components/carousel';
import 'antd/dist/antd.css';
import { Layout, Card, Space, Spin, Row, Col } from 'antd';
import './App.css';
import './mixin.less';
import { LoadingOutlined } from '@ant-design/icons';

const { Content } = Layout
const antIcon = <LoadingOutlined style={{ fontSize: 60, color: 'rgb(140 21 16)' }} spin />;

function App() {
  const [content, setContent] = useState({})
  console.log("ywllo")
  const getData = () => {
    fetch('content.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then((response) => {
        // console.log(response)
        return response.json()
      })
      .then((json) => {
        setContent(json)
      });
  }
  useEffect(() => {
    getData()
  }, [])
  return content.banners ? (
    // <Router>
    <Layout className="App" style={{background: '#fff'}}>
      <Content>
        <div style={{width: '100%'}}>
          <Carousel content={content}/>
        </div>
        <div className='container'>
          <div style={{position: 'relative'}}>
          <div id="about" style={{position: 'absolute', top: "-100px"}}></div>
          <About content={content}/>
          </div>
          <div style={{position: 'relative'}}>
          <div id="features" style={{position: 'absolute', top: "-100px"}}></div>
          <Features content={content}/>
          </div>
          {/* <div id="features"></div>
          <Features/> */}
        </div>
        <Card hoverable={false} style={{position: 'relative', background: 'hsl(0deg 47% 97%)', border: 'none', marginTop: '90px', padding: "50px 0"}} bodyStyle={{padding: '0px'}}>
          <div id="promotions" style={{position: 'absolute', top: "-90px"}}></div>
          <Space style={{width: '100%', justifyContent: 'center'}}>
              <p className='title' style={{marginBottom: '20px'}}>Promotions</p>
          </Space>
          <div style={{paddingBottom: '90px'}} className='container'>
              <Promotions content={content}/>
          </div>
        </Card>
      </Content>
    </Layout>
    // </Router>
  ) : (
    <Row justify='center' align="middle" style={{height: "100vh"}}>
      <Col xs="5">
        <Spin  indicator={antIcon}/>
      </Col>
    </Row>
  );
}

export default App;
