import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Divider, Space, Image } from 'antd';
// import { FacebookFilled } from '@ant-design/icons'

const { Paragraph, Title, Link, Text } = Typography

const style = {
    Divider: {
        borderColor: 'white',
        borderWidth: '0.1px'
    }
}

export function Footer() {
    const [width, setWidth] = useState(window.innerWidth);
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return <Card style={{
        background: 'rgb(140 21 16)',
        border: 'none',
        borderRadius: '50px 50px 0 0',
        padding: '30px 0',
        marginTop: '-40px'
    }} bodyStyle={{padding: '0px'}}>
        <div className='container'>
            <Row  justify="space-between">
                <Col sm={8} style={{paddingTop: '25px'}}>
                    <Row justify={isMobile ? 'center' : ''}>
                    <Title className='white-text rubik' level={3}>Download XPay App</Title>
                    </Row>
                    <Space>
                        <a href="#/"><Image src={require('../assets/app-store.png')} preview={false}/></a>
                        <a href="#/"><Image src={require('../assets/android.png')} preview={false}/></a>
                    </Space>
                </Col>
                <Col xs={24} sm={0} style={{paddingTop: '10px 0'}}>
                    <Divider style={{borderTop: "1px solid rgb(255 255 255 / 54%)"}}/>
                </Col>
                {/* <Col xs={0} sm={10}>
                    <Row justify='center'>
                        <Image preview={false} src={require('../assets/icons/App - Red Icon.png')}/>
                    </Row>
                </Col> */}
                <Col xs={24} sm={7} style={{padding: !isMobile ? '25px 0 0 0' : '0 0 25px 0'}}>
                    <Row justify={isMobile ? 'center' : 'end'}>
                        <div>
                            <Space direction='horizontal' style={{width: '100%', justifyContent: isMobile ? 'center' : ''}}>
                                <Image style={{width: '20px'}} src={require('../assets/icons/facebook.png')}/>
                                <Image style={{width: '20px'}} src={require('../assets/icons/instagram.png')}/>
                                <Image style={{width: '20px'}} src={require('../assets/icons/twitter.png')}/>
                            </Space>
                            <Paragraph style={{marginTop: '1em', textAlign: isMobile ? 'center' : '', marginBottom: isMobile ? 0 : '1em'}}>
                                <Text className='white-text' style={{}}>Ma. Alhivilaa, Anoana Goalhi, <br/> Male', 20163, Maldives <br/> <Link className='white-text' href='tel:9603308586'>+960 3308586</Link></Text>
                            </Paragraph>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    </Card>
}
