// import { useState } from 'react';
import 'antd/dist/antd.css';
// import Slider from "react-slick";
import { Card, Carousel, Row, Col } from 'antd';
import '../App.css';

const { Meta } = Card
function Promotions (props) {
  var settings = {
    dots: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    className: 'promotion-carousel',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  const promotionList = props.content.promotions

  //add promotion items in jsaon file like
//   {
//     "title": "",
//     "image": ""
// }
  return (
    <>
    {promotionList.length > 0 ?
      <Carousel {...settings}>
        {promotionList.map((item, index) => (
          <div key={index}>
            <Card
              className='promotions'
              hoverable={false}
              cover={<img alt="example" src={item.image} />}
            >
              <Meta style={{textAlign: 'center'}} title="iPhones" description={item.title} />
            </Card>
          </div>
        ))}

      </Carousel> : 
      <>
      <p>Stay tuned for upcoming promotions! Currently, we don't have any ongoing offers, but exciting deals and special offers are on the horizon. Keep a close eye on this section for our upcoming promotions and offers.</p>
      </>
    }
    </>
  );
}

export default Promotions;
