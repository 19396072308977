import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { MenuOutlined } from '@ant-design/icons';
import { Layout, Menu, Row, Col, Image, Affix, Modal, Button, Drawer  } from 'antd';
// import Title from 'antd/lib/skeleton/Title';

const { Header } = Layout
export function Nav(props) {
  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [navVisible, setNavVisible] = useState(false);

  const showDrawer = () => {
    setNavVisible(true);
  };

  const onClose = () => {
    setNavVisible(false);
  };
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  const isMobile = width <= 768;
  return (
    <>
      <Header style={{backgroundColor: 'rgb(140 21 16)', position: 'fixed', zIndex: 1, width: '100%', height: isMobile ? 'auto' : '100px', alignItems: 'end', borderRadius: '0 0 50px 50px'}}>
        <Row justify="center" className="container" style={{padding: isMobile ? '10px 0' : '20px 96px 0 96px'}}>
            <Col xs={15} sm={4}>
              <Image style={{width: isMobile ? '90%' : '90%'}} preview={false} src={require('../assets/icons/App - Red Icon.png')}/>
            </Col>
            {!isMobile ? 
              <Col sm={20}>
                <Row justify="end">
                  <Col>
                        <Menu mode="horizontal" theme='dark' defaultSelectedKeys={['1']} inlineCollapsed={false} style={{backgroundColor: 'rgb(140 21 16)'}}>
                            <Menu.Item key="1"><Link smooth to="/#about">About Us</Link></Menu.Item>
                            <Menu.Item key="2"><Link smooth to="/#features">Features</Link></Menu.Item>
                            <Menu.Item key="3"><Link smooth to="/#promotions">Promotions</Link></Menu.Item>
                            <Menu.Item key="4"><Link to="/privacy-policy">Privacy Policy</Link></Menu.Item>
                            <Menu.Item key="5"><Link smooth to="/#footer">Contact Us</Link></Menu.Item>
                        </Menu>
                  </Col>
                </Row>
              </Col>
            : <><Button
            type='text'
            style={{ color: 'white', fontSize: '20px', marginTop: '8%', marginLeft: '18%' }}
            onClick={showDrawer}
          >
            <MenuOutlined />
          </Button>
          <Drawer title="XPay" placement="right" onClose={onClose} visible={navVisible} headerStyle={{backgroundColor: 'rgb(140 21 16)', color: 'white'}} bodyStyle={{backgroundColor: 'rgb(140 21 16)'}}>
            <Menu
              defaultSelectedKeys={['1']}
              inlineCollapsed={false}
              style={{backgroundColor: 'rgb(140 21 16)', color: 'white'}}
              className='mobile-drawer'
              theme='dark'
            >
              <Menu.Item key="1"><Link smooth to="/#about" onClick={onClose}>About Us</Link></Menu.Item>
              <Menu.Item key="2"><Link smooth to="/#features" onClick={onClose}>Features</Link></Menu.Item>
              <Menu.Item key="3"><Link smooth to="/#promotions" onClick={onClose}>Promotions</Link></Menu.Item>
              <Menu.Item key="4"><Link to="/privacy-policy" onClick={onClose}>Privacy Policy</Link></Menu.Item>
              <Menu.Item key="5"><Link smooth to="/#footer" onClick={onClose}>Contact Us</Link></Menu.Item>
            </Menu>
          </Drawer>
          </>}
        </Row>
        
        <Modal
          centered
          visible={visible}
          onCancel={() => setVisible(false)}
          width={700}
          footer={[
            <Button key="back" onClick={() => setVisible(false)}>
              Close
            </Button>
          ]}
        >
          <p className='modal-title'>Terms & Conditions </p>
          <div className={isMobile ? 'text-justify' : '' } dangerouslySetInnerHTML={{__html: props.content.terms}}></div>
        </Modal>
      </Header>
    </>
  )
}
  