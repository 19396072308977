import { useState, useEffect } from 'react';
// import Slider from "react-slick";
import { Carousel, Image } from 'antd';

function Promotions (props) {
    const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
    };

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    console.log(props)
    // if isMobile
    return (
    <div>
        <Carousel {...settings}>
            {isMobile ? props.content.banners.mobile.map((item, index) => (
                    <div key={index}>
                        <Image preview={false} src={item}/>
                    </div>
                )) : props.content.banners && props.content.banners.desktop.map((item, index) => (
                    <div key={index}>
                        <Image preview={false} src={item}/>
                    </div>
                ))
            }
        </Carousel>
    </div>
    );
}

export default Promotions;
