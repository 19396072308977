// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import './index.css';
// import App from './App';
// import Privacy from './Views/Privacy';
// import NotFround from "./Views/404"
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<App />}>
//           <Route index element={<App />} />
//           <Route path="privacypolicy" element={<Privacy />} />
//           <Route path="*" element={<NotFround />} />
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );


// // const root = ReactDOM.createRoot(document.getElementById('root'));
// // root.render(<Main />);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // Import from React Router DOM v5.2
import { Layout } from 'antd';
import './index.css';
import App from './App';
import { Nav } from './Components/Nav';
import { Footer } from './Components/Footer';
import Privacy from './Views/Privacy';
import NotFound from "./Views/404"
import reportWebVitals from './reportWebVitals';

const { Content } = Layout

ReactDOM.render(
  <React.StrictMode>
    <Router  basename="/">
      <div> 
      <Nav content={Content}/>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="*" component={NotFound} />
      </Switch>
      
      <div id="footer"/>
      <Footer/>
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

