import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Typography, Row, Col, Image, Button, Space, Card } from 'antd';
import { fadeInRight } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import '../App.css';

const { Paragraph, Text, Title } = Typography
const styles = {
    fadeInRight: {
      animation: 'x 1s',
      animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
    }
  }
function Features(props) {
    const [feature, setFeature] = useState('BILL PAY');
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

//   console.log(props.content.features)
  const featureList = props.content.features.feature_list
  console.log(featureList)

    const ShowFeature =({feature, list}) => {
        const feature_obj = list.filter(item => item.feature.toLowerCase() === feature.toLowerCase())[0]
        // console.log(list.filter(item => item.feature.toLowerCase() === feature.toLowerCase()))
        return <StyleRoot key={feature}>
            <div style={{...styles.fadeInRight}}>
                <Space direction='vertical' style={{height: '100%', width: '100%', alignContent: 'center', textAlign: 'center'}}>
                    <Space direction='horizontal' style={{width: '100%', justifyContent: 'center'}}>
                        <Card>
                            <Image src={feature_obj.image} width={60} preview={false}/>
                        </Card>
                    </Space>
                    <div style={{marginTop: '20px'}}>
                        <Title style={{color: "#A52B2A"}} level={4}>{feature_obj.feature}</Title>
                        <Text>{feature_obj.description}</Text>
                    </div>
                </Space>
            </div>
        </StyleRoot>
    }
  return featureList ? (
        <Row>
            {!isMobile ? <>
                <Col sm={14}>
                    <Row align='middle'>
                        <Col span={8}>
                            <ShowFeature feature={feature} list={featureList}/>
                        </Col>
                        <Col xs={24} sm={16} style={{justifyContent: 'center'}}>
                          <Row justify="center">
                            <div style={{display: 'inline-block', position: 'relative'}}>
                                    {/* / Bill Pay */}
                                    <Button type='text' onMouseOver={() => setFeature('BILL PAY')} size='small' className='hiddenButtonStytle' style={{left: '23%', top: '44%'}}>&nbsp;</Button>
                                    {/* top up */}
                                    <Button type='text' onMouseOver={() => setFeature('TOP-UP')} size='small' className='hiddenButtonStytle' style={{left: '45.5%', top: '44%'}}>&nbsp;</Button>
                                    {/* Tv */}
                                    <Button type='text' onMouseOver={() => setFeature('TV')} size='small' className='hiddenButtonStytle' style={{left: '68%', top: '44%'}}>&nbsp;</Button>
                                    {/* Donate */}
                                    <Button type='text' onMouseOver={() => setFeature('DONATIONS')} size='small' className='hiddenButtonStytle' style={{left: '23%', top: '53%'}}>&nbsp;</Button>
                                    {/* Insurance */}
                                    <Button type='text' onMouseOver={() => setFeature('INSURANCE')} size='small' className='hiddenButtonStytle' style={{left: '45.5%', top: '53%'}}>&nbsp;</Button>
                                    {/* Gas */}
                                    <Button type='text' onMouseOver={() => setFeature('ORDER GASE')} size='small' className='hiddenButtonStytle' style={{left: '68%', top: '53%'}}>&nbsp;</Button>
                                    {/* Gift Cards */}
                                    <Button type='text' onMouseOver={() => setFeature('GIFT CARDS')} size='small' className='hiddenButtonStytle' style={{left: '23%', top: '62%'}}>&nbsp;</Button>
                                    {/* Games */}
                                    <Button type='text' onMouseOver={() => setFeature('GAME')} size='small' className='hiddenButtonStytle' style={{left: '45.5%', top: '62%'}}>&nbsp;</Button>
                                    {/* Movie Ticket */}
                                    <Button type='text' onMouseOver={() => setFeature('MOVIES')} size='small' className='hiddenButtonStytle' style={{left: '68%', top: '62%'}}>&nbsp;</Button>
                                    {/* Transport */}
                                    <Button type='text' onMouseOver={() => setFeature('TRANSPORT')} size='small' className='hiddenButtonStytle' style={{left: '23%', top: '70%'}}>&nbsp;</Button>
                                    {/* Broadband */}
                                    <Button type='text' onMouseOver={() => setFeature('Broadband')} size='small' className='hiddenButtonStytle' style={{left: '44.5%', top: '70%'}}>&nbsp;</Button>
                                    {/* School fees */}
                                    <Button type='text' onMouseOver={() => setFeature('School Fee')} size='small' className='hiddenButtonStytle' style={{left: '60%', top: '70%'}}>&nbsp;</Button>

                                    <Image
                                    src={require('../assets/features.jpeg')}
                                    preview={false}
                                    // placeholder={
                                    //     <Image
                                    //     src={require('../assets/features blur.jpeg')}
                                    //     preview={false}/>
                                    // }
                                    ></Image>
                            </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </> : ''}
            <Col xs={24} sm={10}>
                <p className='title'>Features</p>
                <div className={isMobile ? 'text-justify paragraph' : 'paragraph' } dangerouslySetInnerHTML={{__html: props.content.features.description}}/>
            </Col>
            {isMobile ? <div>
                {featureList.map((item, index) => (
                    <div key={index} style={{marginTop: '50px'}}>
                        <Space direction='vertical' style={{height: '100%', width: '100%', alignContent: 'center', textAlign: 'center'}}>
                            <Space direction='horizontal' style={{width: '100%', justifyContent: 'center'}}>
                                <Card>
                                    <Image src={item.image} width={60} preview={false}/>
                                </Card>
                            </Space>
                            <div style={{marginTop: '20px'}}>
                                <Text>{item.description}</Text>
                            </div>
                        </Space>
                    </div>
                ))}
            </div> : ''}
        </Row>
  ) : '';
}

export default Features;
