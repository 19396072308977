import React, {useEffect, useState} from "react";
import { Typography, Row, Col, Image } from 'antd';
const { Paragraph } = Typography
export function About(props) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;
  return <Row style={{
    margin: '50px 0'
  }}>
    <Col span={12} xs={24} sm={12}>
      <p className='title'>About Us</p>
      <div className={isMobile ? 'text-justify' : '' } dangerouslySetInnerHTML={{__html: props.content.about}}></div>
    </Col>
    <Col xs={24} sm={12} style={{
      padding: '50px 0',
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Image className="center-img" src={require('../assets/about us.jpeg')} preview={false} placeholder={<Image src={require('../assets/about us blur.jpeg')} preview={false} />}></Image>
    </Col>
  </Row>;
}
  